.dashboard {
  .box-white {
    background-color: var(--white-color);
    height: auto;
    border-radius: 14px;
    padding: 24px;

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--dark);
    }

    h2.title-avaliacao {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--dark);
    }

    p.descricao-avaliacao {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      color: var(--dark);
    }

    h2.title-classificacao {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      color: var(--dark);
    }

    p.descricao-classificacao {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      color: var(--dark);

      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 24px;
      }

      img {
        width: 30px;
        height: auto;
        margin: -7px 0 0 5px;
      }
    }

    img {
      width: 500px;
      height: auto;
    }

    .emoji {
      width: 30px;
      height: auto;
    }

    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      color: #252379;
    }

    h4 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: var(--dark);
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark);
    }

    .plus {
      margin-top: -10px;
      margin-right: 5px;
    }

    .dot-orange {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: var(--toast);
      margin-right: 5px;
    }

    .dot-green {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: var(--green-color);
      margin-right: 5px;
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: var(--toast);
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--dark-secondary);
    }

    .steps {
      .ant-steps-item-finish
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot,
      .ant-steps-item-process
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot,
      .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        display: none !important;
      }

      .ant-steps-vertical
        > .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        width: 0px !important;
      }

      .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
        margin-top: 12px;
      }

      .box-step-ative {
        border-left: 3px solid var(--primary-color);
        border-radius: 4px;

        .ant-steps-item-description {
          color: var(--primary-color) !important;
          font-weight: bold;
        }
      }
      .box-step-concluido {
        border-left: 3px solid var(--green-color);
        border-radius: 4px;

        .ant-steps-item-description {
          color: var(--green-color) !important;
        }
      }

      .box-step-disable {
        border-left: 3px solid var(--blue-gray);
        border-radius: 4px;

        .ant-steps-item-description {
          color: var(--blue-gray) !important;
        }
      }
    }

    .ant-radio-group,
    .ant-space-vertical {
      width: 100%;
    }

    .box-radio {
      width: 100%;
      height: auto;

      padding: 24px;

      background-color: transparent;

      border: 1px solid var(--primary-color);
      border-radius: 16px;

      box-sizing: border-box;
      box-shadow: 0px 0px 4px rgba(26, 77, 161, 0.5);

      display: flex;
      align-items: center;
      gap: 8px;

      .ant-radio-wrapper {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: var(--dark);

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 0px !important;
          color: var(--dark);
        }
      }
    }

    :where(.css-dev-only-do-not-override-dkbvqv).ant-radio-wrapper
      span.ant-radio
      + * {
      display: flex;
      align-items: center;
      gap: 8px;

      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
    }

    .box-radio-select {
      background-color: var(--primary-color);
    }

    .box-radio-select__text {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0px !important;
      color: var(--white-color) !important;
    }

    .box-radio-select-correto {
      background-color: #4caf50;
      border: 1px solid #4caf50;
      box-shadow: 0px 0px 4px #4caf50;

      .ant-radio-wrapper {
        color: var(--white-color) !important;

        p {
          color: var(--white-color) !important;
        }
      }
    }

    .box-radio-select-errado {
      background-color: #dd3736;
      border: 1px solid #dd3736;
      box-shadow: 0px 0px 4px #dd3736;

      .ant-radio-wrapper {
        color: var(--white-color) !important;

        p {
          color: var(--white-color) !important;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #box-aula-white {
    width: 59% !important;
  }
  #box-aula-white-right {
    width: 39% !important;
  }
}

@media screen and (max-width: 767px) {
  #box-aula-white {
    width: 100% !important;
    max-height: none !important;
  }
  #box-aula-white-right {
    width: 100% !important;
    max-height: none !important;
  }

  #aula-box {
    flex-direction: column;
  }
}

.alternativa-descricao {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #92a9cb !important;
}

.feedback-text-area {
  border: 1px solid var(--primary-color);
  border-radius: 16px;

  width: 100%;

  padding: 8px;

  display: flex;
  flex-direction: column;

  label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #344453;
    margin-bottom: 3px;
  }
}
.flex-container {
  display: flex;
  margin: 8px 0;
}

.index-heading {
  padding: 12px;
  font-size: 18px;
}
.media {
  width: 100%;
  height: auto;
}
