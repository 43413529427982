.arquivo-card-container {
  width: 75%;
  height: auto;

  border-radius: 8px;
  background-color: var(--white-color);

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
 
}

.arquivo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  justify-content: center;
  height: 100%;

  h1{
    word-break: break-all;
    color: #344453;
    font-size: 14px;
    font-weight: 600;
  }
  svg{
    color: #0d306b;
  }

}
.arquivo-title{
  min-height: 50px;
}

.webinar-infos__webinar-image-title {
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
}

.webinar-image-title__title {
  color: #344453;
  font-size: 18px;
  font-weight: 600;
}

.webinar-infos__webinar-rewards {
  display: flex;
  align-items: center;
  gap: 8px;
}

.webinar-rewards__reward-label-value {
  display: flex;
  flex-direction: column;
}

.reward-label-value__label {
  color: rgba(52, 68, 83, 0.5647058824);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.reward-label-value__rewards-coins {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: #344453;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  img {
    width: 24px;
    height: 24px;
  }
}
