.trilha-id-container__top-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.top-content__back-button-icon-title {
  display: flex;
  align-items: center;
  gap: 24px;
}

.back-button-icon-title__icon-title,
.top-content__happy-coins-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.happy-coins-container__label {
  color: rgba(52, 68, 83, 0.5647058824);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.happy-coins-container__coins-value {
  display: flex;
  gap: 8px;

  p {
    color: var(--dark);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.trilha-id-container__searchbar-input {
  width: 100%;

  position: relative;
}

.searchbar-input__search-icon {
  position: absolute !important;

  top: 50% !important;
  left: 24px !important;
  transform: translate(-50%, -50%) !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar-input__input-search {
  width: 100%;
  height: 40px !important;

  padding: 8px 16px !important;
  padding-left: 48px !important;

  border-radius: 8px;
}

.trilha-id-container__trihas-result-banner {
  width: 100%;

  padding: 4px 12px;

  background-color: var(--blue-color-20);

  color: var(--blue-light);
  font-weight: 700;

  border-radius: 8px;
  border-left: 10px solid var(--blue-light);

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: transparent;
  }
}
.trilha-id-container__avaliacao{
  width: 50%;
  display: flex;
}

.trilha-id-container__trilha-cards {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}
.trilha-id-container__avaliacao_container{
  display: flex;
  width: 175%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.trilha-id-container__certificado{
  width: 50%;
  display: flex;
}

@media (min-width: 640px) {
  .trilha-id-container__trilha-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .trilha-id-container__trilha-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .trilha-id-container__trilha-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
