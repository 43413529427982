.minhas-aulas-happy-container{
  width: 100%;
  height: auto;

  border-radius: 8px;
  background-color: var(--white-color);

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minhas-aulas-happy-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2{
    font-size: 26px;
    line-height: 36px;
    font-weight: bolder;
  }
}


.minhas-aulas-happy-container{
  width: 100%;
  height: auto;

  border-radius: 8px;
  background-color: var(--white-color);

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minhas-aulas-happy-create-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--white-color);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.minhas-aulas-happy-create-header {
  text-align: center;

  h1 {
    font-size: 24px;
    font-weight: bold;
  }
}

.minhas-aulas-happy-create-form {
  width: 100%;

  .ant-form-item {
    margin-bottom: 16px;

    label {
      font-size: 14px;
      color: var(--text-color);
    }

    input,
    .ant-input {
      border-radius: 6px;
      padding: 8px 12px;
      font-size: 14px;
    }

    .ant-radio-group {
      display: flex;
      gap: 16px;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 16px;

    .button-secondary {
      background-color: var(--secondary-color);
      color: var(--white-color);
      border: none;
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
    }

    .button-primary {
      background-color: var(--primary-color);
      color: var(--white-color);
      border: none;
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.upload-container{
  text-align: center;
  display: flex;
  gap: 10px;
}
.upload-info{
  margin: 8px 0px;
  color: red;
  opacity: 0.6;
}
.title-archive{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0px;
}

.box-archive {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.outlined-form .ant-input,
.outlined-form .ant-input-number,
.outlined-form .ant-select-selector {
  background-color: #f5f5f55b;
}

